<template>
  <div class="card">
    <div class="card-body">
      <registration-form
        :config="formConfig.steps"
        :disabled="isDisabled"
        v-model="registrationData"
        @submit="doSubmit"
      />
    </div>
  </div>
</template>

<script>
import RegistrationForm from "@/components/BaseForm.vue";

export default {
  components: { RegistrationForm },
  data() {
    return {
      form: this.$route.params.id,
      isLoading: false,
      registrationData: {},
      isDisabled: false,
    };
  },
  computed: {
    formConfig() {
      return this.$store.getters["Registration/form"];
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
  methods: {
    async doSubmit() {
      this.isLoading = true;
      await this.$store.dispatch(
        "Auth/addFormRegistration",
        this.registrationData
      );
      if (this.registrationData.complete?.length > 0) {
        this.isDisabled = true;
      }
      this.isLoading = false;
      this.$router.push({ path: this.formConfig.link });
    },
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("Registration/fetchById", "form" + this.form);
    if (this.currentUser["additional_info_form" + this.form] !== null) {
      this.registrationData = this.currentUser[
        "additional_info_form" + this.form
      ];
    }
    this.registrationData.form = this.form;
    if (this.registrationData.complete?.length > 0) {
      this.isDisabled = true;
    }
    this.isLoading = false;
  },
};
</script>
